import React from 'react';

import MenuItem from './MenuItem';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot, faPhone, faFax, faEnvelope, faUser} from '@fortawesome/free-solid-svg-icons'

import Kontaktdaten from './Kontaktdaten';


const Kontakt = (props) => {
    return (
        <div className="contact">
            <Container>                
                <Card
                    style={
                        {
                            backgroundColor: props.bg,
                            border: "none",
                            margin: "10px 0"
                        }
                    }
                    className=""
                    >
                    <Card.Body>
                        <Container>
                        <h3>ABCplus-Apotheke</h3>
                        <Kontaktdaten />
                            
                            <Row style={{marginTop: "20px"}}>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2516.492451295855!2d8.025009343541349!3d50.896103533471305!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47bc1cb6befdf685%3A0xb20f5a81d0268964!2sABC%20plus%20Apotheke!5e0!3m2!1sde!2sde!4v1681393958601!5m2!1sde!2sde" width="100%" height="300" style={{border: 0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </Row>
                        </Container>
                    </Card.Body>
                </Card>
            </Container>
            <Container>
                
            </Container>
        </div>        
    )
}

export default Kontakt;